import type { GetProp, UploadProps } from 'antd';
import { message } from 'antd';
import { request } from '../../../../utils';
import type CommunityModel from '../../model';
import { action, observable } from 'mobx';

interface resultValue<T> {
  code: number;
  data?: T;
  msg?: string;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export default class Model {
  @observable public visible = false;

  @observable public saveLoading = false; // 保存状态

  @observable public id = null; // 群组id

  @observable public parent = null;

  @observable public file: FormData = null;

  @observable public errorInfo: { list: string[]; total: number; failCount: number } = {
    list: [],
    total: 0,
    failCount: 0,
  };

  constructor(parent: CommunityModel) {
    this.parent = parent;
  }

  @action
  public onShow = (data?: any) => {
    this.visible = true;
    if (data.id) {
      this.id = data.id;
    }
  };

  @action
  public onClose = () => {
    this.visible = false;
    this.file = null;
    this.errorInfo = {
      list: [],
      total: 0,
      failCount: 0,
    };
  };

  public beforeUpload = (file: FileType) => {
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const isXls = file.type === 'application/vnd.ms-excel';
    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isXlsx && !isXls) {
      message.error('上传文件只能是 xls 或者 xlsx 格式!');
      return false;
    }
    if (!isLt5M) {
      message.error('上传文件大小不能超过 5MB!');
      return false;
    }
    return true;
  };

  @action
  public onSave = (file: FormData) => {
    if (!file) {
      message.error('请上传文件');
      return;
    }
    this.saveLoading = true;
    this.errorInfo = {
      list: [],
      total: 0,
      failCount: 0,
    };
    request<resultValue<{ successOrNot: number; failMsg: string[]; totalCount: number; failCount: number }>>({
      url: `/quan/back/biz/expert/excel/import?groupId=${this.id}`,
      method: 'POST',
      data: file,
    })
      .then((res) => {
        // 错误信息
        if (res.code === 200 && res?.data?.successOrNot === 0) {
          this.errorInfo.total = res?.data?.totalCount || 0;
          this.errorInfo.failCount = res?.data?.failCount || 0;
          this.errorInfo.list = res?.data?.failMsg || [];
          console.log('🚀 ~ Model ~ .then ~ this.errorInfo:', this.errorInfo);
          return;
        }
        message.success(res.msg || '操作成功');
        this.onClose();
        this.parent.mainSubStructureModel?.gridModel?.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
