import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { Button, Card, Cascader, Checkbox, Col, Form, Image, Input, InputNumber, message, Radio, Row, Select, Space, Tag, Transfer, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { FullModal, singleUploadObs } from '../../../../utils';
import compressImgOrVideo from '../../../../utils/compress';
import AddAnchor from './addAnchor';
import styles from './index.less';
import type AddModel from './model';

const OBS_PATH = 'community/avatar/image/';
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG!');
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('图片必须小于5MB!');
    return false;
  }
  return isJpgOrPng && isLt2M;
};
@observer
export default class Add extends React.Component<{ store: AddModel }> {
  render() {
    const { visible, editId, setType, type, onSubmit, clearPriceTemplate, parentStore, getPriceCategoryName, detailList, loading, huaWeiObsConfig, onCancel, formRef, cornerMarkers, activeLabelList } = this?.props?.store || {};

    return (
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        ref={formRef}
      >
        <FullModal
          title={editId ? '编辑社群' : '添加社群'}
          visible={visible}
          onCancel={onCancel}
          operation={
            <Button
              onClick={onSubmit}
              type="primary"
            >
              保存
            </Button>
          }
        >
          <div className={styles.content}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="类型"
              initialValue={0}
              name="groupType"
              rules={[{ required: true }]}
            >
              <Radio.Group
                onChange={(e) => {
                  setType(e.target.value);
                }}
                disabled={Boolean(editId)}
                options={[
                  {
                    label: '主播',
                    value: 0,
                  },
                  {
                    label: '达人',
                    value: 1,
                  },
                  {
                    label: '兼职',
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="firstCategoryId"
                  label="一级类别"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="请选择"
                    onChange={(value) => {
                      clearPriceTemplate();
                      formRef.current?.setFieldsValue({ secondCategoryId: undefined });
                      parentStore.getCategorySecond(value);
                    }}
                    options={parentStore.firsList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="secondCategoryId"
                  label="二级类别"
                  rules={[{ required: true }]}
                >
                  <Select
                    onChange={() => {
                      clearPriceTemplate();
                    }}
                    placeholder="请选择"
                    options={parentStore.secondList}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="cityId"
                  label="群区域"
                  rules={[{ required: true }]}
                >
                  <Cascader
                    onChange={() => {
                      clearPriceTemplate();
                    }}
                    placeholder="请选择"
                    options={parentStore.cityList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="managerId"
                  label="管理员"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={parentStore.userList}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群名称"
              rules={[{ required: true }]}
              name="groupName"
            >
              <Input maxLength={15} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群价格"
              shouldUpdate
              required
            >
              {({ getFieldsValue, setFieldValue }) => {
                const { firstCategoryId, secondCategoryId, cityId } = getFieldsValue();
                return (
                  <div>
                    <Space>
                      <Button
                        onClick={() => {
                          const [parentRegionId, regionId] = cityId;
                          parentStore.getPriceTemplateList({
                            parentCategoryId: firstCategoryId,
                            categoryId: secondCategoryId,
                            parentRegionId,
                            regionId,
                          });
                          parentStore.setPriceTemplateOpen();
                        }}
                        disabled={!firstCategoryId || !secondCategoryId || !cityId}
                        type="link"
                      >
                        导入模版
                      </Button>
                      <Button
                        disabled={!firstCategoryId || !secondCategoryId || !cityId}
                        type="link"
                        onClick={() => {
                          parentStore.setPriceOpen();
                        }}
                      >
                        独立设置
                      </Button>
                    </Space>
                    {detailList.length ? (
                      <Card className={styles.card}>
                        {detailList.map((item, index) => (
                          <Space
                            className={styles.detailListItem}
                            key={`${index.toString()}detailList`}
                          >
                            标题
                            <span>{item.title}</span>
                            会员时长
                            <span className={styles.duration}>{item.duration}</span>
                            {getPriceCategoryName(item.priceCategory)}
                            价格
                            <span className={styles.price}>{item.price}元</span>
                          </Space>
                        ))}
                      </Card>
                    ) : undefined}
                  </div>
                );
              }}
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="样板群"
              name="isTemplate"
              valuePropName="checked"
            >
              <Checkbox>样板群</Checkbox>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="排序"
              name="sort"
            >
              <InputNumber
                min={0}
                max={99999}
                precision={0}
              />
            </Form.Item>

            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label={type === 1 ? '达人数' : '群人数'}
              name="anchorCount"
              rules={[{ required: true }]}
            >
              <Input maxLength={6} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群角标"
              shouldUpdate
              extra="最多选择3个"
            >
              {({ setFieldValue }) => {
                const handleChange = (tag: any, checked: boolean) => {
                  if (checked && activeLabelList?.length >= 3) {
                    return;
                  }
                  if (checked) {
                    activeLabelList.push(tag.value);
                  } else {
                    if (activeLabelList.includes(tag.value)) {
                      activeLabelList.splice(activeLabelList.indexOf(tag.value), 1);
                    }
                  }
                  tag.checked = checked;
                  setFieldValue('labelIds', activeLabelList);
                };
                return (
                  <Form.Item
                    className={styles.formItem}
                    name="labelIds"
                    validateTrigger="onChange"
                  >
                    {cornerMarkers?.map<React.ReactNode>((tag) => (
                      <Tag.CheckableTag
                        key={tag.value}
                        checked={tag.checked}
                        onChange={(checked) => handleChange(tag, checked)}
                      >
                        {tag.label}
                      </Tag.CheckableTag>
                    ))}
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="群简介"
              name="groupDesc"
              rules={[{ required: true }]}
            >
              <Input.TextArea maxLength={20} />
            </Form.Item>
            <Form.Item
              label="群头像"
              required
              shouldUpdate
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              className={styles.formItem}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupAvatar = getFieldValue('groupAvatar');
                return (
                  <Form.Item
                    className={styles.formItem}
                    name="groupAvatar"
                    getValueFromEvent={(e) => groupAvatar}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请上传群头像',
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      beforeUpload={beforeUpload}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupAvatar', url);
                      }}
                    >
                      {groupAvatar && typeof groupAvatar === 'string' ? (
                        <img
                          src={groupAvatar}
                          alt="avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 1 张</span>
            </div>
            <Form.Item
              label="群二维码"
              shouldUpdate
              required
              labelCol={{ span: 3 }}
              className={styles.formItem}
              wrapperCol={{ span: 21 }}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupCode = getFieldValue('groupCode');
                return (
                  <Form.Item
                    className={styles.formItem}
                    validateTrigger="onBlur"
                    getValueFromEvent={(e) => groupCode}
                    name="groupCode"
                    rules={[
                      {
                        required: true,
                        message: '请上传群二维码',
                      },
                    ]}
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupCode', url);
                      }}
                      beforeUpload={beforeUpload}
                    >
                      {groupCode && typeof groupCode === 'string' ? (
                        <img
                          src={groupCode}
                          alt="avatar"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 1 张</span>
            </div>
            <Form.Item
              label="群聊天"
              shouldUpdate
              labelCol={{ span: 3 }}
              className={styles.formItem}
              validateTrigger="onBlur"
              wrapperCol={{ span: 21 }}
            >
              {({ getFieldValue, setFieldValue }) => {
                const groupChatList = getFieldValue('groupChatList');
                return (
                  <Form.Item
                    className={styles.formItem}
                    name="groupChatList"
                    getValueFromEvent={(e) => groupChatList.filter((i: any) => i.status !== 'removed')}
                    initialValue={[]}
                  >
                    <Upload
                      listType="picture-card"
                      fileList={groupChatList}
                      beforeUpload={beforeUpload}
                      maxCount={4}
                      // multiple
                      customRequest={async (i: any) => {
                        const unit = i.file.name.split(',');
                        const file: any = await compressImgOrVideo(i.file);
                        if (file === false) {
                          return;
                        }
                        const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                        setFieldValue('groupChatList', [
                          ...groupChatList,
                          {
                            uid: uniqueId(),
                            name: i.file.type,
                            status: 'done',
                            url,
                          },
                        ]);
                      }}
                      onRemove={(values) => {
                        setFieldValue('groupChatList', [...groupChatList.filter((item) => item.url !== values.url)]);
                      }}
                    >
                      {groupChatList && groupChatList.length >= 4 ? null : (
                        <button
                          style={{
                            border: 0,
                            background: 'none',
                          }}
                          type="button"
                        >
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div style={{ marginTop: 8 }}>上传</div>
                        </button>
                      )}
                    </Upload>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 4 张</span>
            </div>
            <Form.Item
              label="群分享图"
              shouldUpdate
              labelCol={{ span: 3 }}
              className={styles.formItem}
              wrapperCol={{ span: 21 }}
            >
              {({ getFieldValue, setFieldValue }) => {
                const sharePic = getFieldValue('sharePic');
                return (
                  <Form.Item
                    className={styles.formItem}
                    validateTrigger="onBlur"
                    getValueFromEvent={(e) => sharePic}
                    name="sharePic"
                  >
                    <ImgCrop
                      showReset
                      rotationSlider={false}
                      aspect={424 / 340}
                    >
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        maxCount={1}
                        showUploadList={false}
                        customRequest={async (i: any) => {
                          const unit = i.file.name.split(',');
                          const file: any = await compressImgOrVideo(i.file);
                          if (file === false) {
                            return;
                          }
                          const url = await singleUploadObs(file, `${OBS_PATH}${i.file.uid}${unit[unit.length - 1]}`);
                          setFieldValue('sharePic', url);
                        }}
                        beforeUpload={beforeUpload}
                        onRemove={() => {}}
                      >
                        {sharePic && typeof sharePic === 'string' ? (
                          <img
                            src={sharePic}
                            alt="avatar"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        ) : (
                          <button
                            style={{
                              border: 0,
                              background: 'none',
                            }}
                            type="button"
                          >
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div style={{ marginTop: 8 }}>上传</div>
                          </button>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <div className={styles.tips}>
              支持JPG、PNG，大小不超过5M <span>最多 1 张（标准尺寸规范：424*340）</span>
            </div>
            <Form.Item
              label="添加群成员"
              shouldUpdate
              labelCol={{ span: 3 }}
              className={styles.formItem}
              wrapperCol={{ span: 21 }}
            >
              <AddAnchor store={this?.props?.store} />
            </Form.Item>
          </div>
        </FullModal>
      </Form>
    );
  }
}
